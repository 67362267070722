import { extendTheme } from '@chakra-ui/react';

export const defaultTheme = extendTheme({
  colors: {
    green: '#CEEDFF',
    blackPearl: '#09162A',
    orange: '#FD972E',
    quoteBoxBackground: 'rgba(178, 188, 203, 0.3)'
  },
  fontFamilies: {
    roboto: 'Roboto',
    montserrat: 'Montserrat',
  },
  size: {
    mobileS: '320px',
    mobileM: '376px',
    mobileL: '426px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
  device: {
    mobileS: `(min-width: 320px)`,
    mobileM: `(min-width: 376px)`,
    mobileL: `(min-width: 426px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1011px)`,
    laptopS: '(min-width: 1040px)',
    laptopM: `(min-width: 1202px) `,
    laptopL: `(min-width: 1440px)`,
    desktop: `(min-width: 2560px)`,
  },
  fontSizes: {
    pageNotFoundTop: '180px',
    pageNotFoundBottom: '60px',
    mobileHeader: '20px',
    mobileSubtitle: '15px',
    mobileSubtitle2: '12px',
    header: '60px',
    subtitle: '40px',
    subtitle1: '25px',
    subtitle2: '20px',
    subtitle3: '18px',
    subtitle4: '16px',
    subtitle5: '12px',
    buttonFont: '30px',
  },
  fontWeight: {
    regular: 400,
    bold: 700,
  },
});
