import { Box, Flex } from '@chakra-ui/layout';
import { Img } from '@chakra-ui/react';
import styled from 'styled-components';

export const CustomFlex = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 350px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ServiceImage = styled(Img)`
  height: 272px;
  object-fit: cover;
  object-position: 100% 60%;
`;

export const ServiceName = styled(Box)`
  height: 104px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  white-space: pre;
`;
