import React from 'react';

import * as S from './styles';

type Props = {
  buttonClass: string;
  buttonName: string;
  path: string;
  height?: string;
  width?: string;
  fontSize?: string;
  fontWeight?: string;
};

export default function MasonaryButton(props: Props) {
  const { buttonClass, buttonName, path, height, width, fontSize, fontWeight } =
    props;

  const routeChange = () => {
    window.location.href = path;
  };

  return (
    <S.StyledButton
      className={buttonClass}
      fontSize={fontSize}
      fontWeight={fontWeight}
      height={height}
      width={width}
      onClick={routeChange}
    >
      {buttonName}
    </S.StyledButton>
  );
}
