import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import routes from '../src/constants/routes';

import Footer from './components/Footer';
import Navbar from './components/Navbar';

import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import Services from './pages/Services';
import { defaultTheme } from './themes/default';

function App() {
  return (
    <ChakraProvider theme={defaultTheme}>
      <ThemeProvider theme={defaultTheme}>
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route element={<Home />} path={routes.HOME} />
            <Route element={<ContactUs />} path={routes.CONTACT_US} />
            <Route element={<Services />} path={routes.SERVICES} />
            <Route element={<PageNotFound />} path="*" />
          </Routes>
        </BrowserRouter>
        <Footer />
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
