import { Box, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const PageNotFoundBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
`;
export const PageNotFoundTop = styled(Text)`
  color: ${(props) => props.theme.colors.orange};
  font-size: ${(props) => props.theme.fontSizes.pageNotFoundTop};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-align: center;
`;

export const PageNotFoundBottom = styled(Text)`
  color: ${(props) => props.theme.colors.orange};
  font-size: ${(props) => props.theme.fontSizes.pageNotFoundBottom};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  text-align: center;
`;
