import { Flex, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const CustomFlex = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.subtitle3};
  background-color: ${(props) => props.theme.colors.blackPearl};

  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSizes.subtitle2};
  }
  @media ${(props) => props.theme.device.laptopM} {
    font-size: ${(props) => props.theme.fontSizes.subtitle1};
    flex-direction: row;
  }
`;

export const CustomText = styled(Text)`
  color: white;
  align-tems: center;
  margin-bottom: 10px;
  text-align: center;

  @media ${(props) => props.theme.device.laptopM} {
    height: 98px;
    width: 814px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 0px;
  }
`;
