import BarbecueAndOutdoorKitchens from '../assets/BarbecueAndOutdoorKitchens.png';
import BlockAndRetainingWall from '../assets/BlockAndRetainingWall.png';
import DecorativeConcrete from '../assets/DecorativeConcrete.jpg';
import DrivewayExtensions from '../assets/DrivewayExtensions.jpg';
import FirePit from '../assets/FirePit.png';
import LandscapingAndGardenWall from '../assets/LandscapingAndGardenwall.jpg';
import PoolDeck from '../assets/PoolDeck.png';
import StonePavementAndBrickWork from '../assets/StonePavementAndBrickWork.jpg';
import { service } from '../types/service';

export const services: service[] = [
  {
    serviceImage: StonePavementAndBrickWork,
    serviceName: 'STONE,\nPAVER &\nBRICK WORK',
  },
  {
    serviceImage: BlockAndRetainingWall,
    serviceName: 'BLOCK &\nRETAINING\nWALL',
  },
  {
    serviceImage: DrivewayExtensions,
    serviceName: 'DRIVEWAY &\nWALKWAY AND\nSTEPS',
  },
  {
    serviceImage: DecorativeConcrete,
    serviceName: 'DECORATIVE CONCRETE',
  },
  {
    serviceImage: FirePit,
    serviceName: 'FIRE PIT',
  },
  {
    serviceImage: PoolDeck,
    serviceName: 'POOL DECK',
  },
  {
    serviceImage: BarbecueAndOutdoorKitchens,
    serviceName: 'BARBECUE &\nOUTDOOR\nKITCHENS',
  },
  {
    serviceImage: LandscapingAndGardenWall,
    serviceName: 'LANDSCAPING &\nGARDEN WALL',
  },
];
