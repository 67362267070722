import {
  Box,
  Flex,
  Img,
  Text
} from '@chakra-ui/react';
import styled from 'styled-components';

import CompanyLogoSvg from '../../assets/CompanyLogo.svg';
import CompanyLogoMobileContactUs from '../../assets/CompanyLogoMobileContactUs.svg';

export const CustomFlex = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 216px;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;

  @media ${(props) => props.theme.device.laptop} {
    height: 110px;
    flex-direction: row;
    background-color: white;
  }
`;

export const CustomFlexMobileContactUs = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 216px;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;
  background-color: ${(props) => props.theme.colors.blackPearl};

  @media ${(props) => props.theme.device.laptop} {
    height: 110px;
    flex-direction: row;
    background-color: white;
  }
`;

export const CustomFlexContactUs = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 216px;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;

  @media ${(props) => props.theme.device.laptop} {
    height: 110px;
    flex-direction: row;
  }
`;

export const CompanyLogoMobile = styled(Img)`
  /* margin-bottom: 18px; */
  content: url(${CompanyLogoMobileContactUs});

  @media ${(props) => props.theme.device.laptop} {
    margin-bottom: 0px;
    content: url(${CompanyLogoSvg});
  }
`;

export const CompanyLogo = styled(Img)`
  /* margin-bottom: 18px; */

  @media ${(props) => props.theme.device.laptop} {
    margin-bottom: 0px;
  }
`;

export const CopyrightText = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.regular};
  order: 4;
  margin-top: 27px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subtitle5};
  text-align: center;

  @media ${(props) => props.theme.device.laptop} {
    margin-top: 0px;
    margin-bottom: 0px;
    order: 2;
  }
`;

export const CopyrightTextMobileContactUs = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.regular};
  order: 4;
  margin-top: 27px;
  color: white;
  text-align: center;

  @media ${(props) => props.theme.device.laptop} {
    margin-top: 0px;
    margin-bottom: 0px;
    order: 2;
    color: black;
  }
`;

export const IconBox = styled(Box)`
  display: flex;
  width: 225px;
  justify-content: space-between;
  order: 3;
  margin-top: 20px;

  @media ${(props) => props.theme.device.laptop} {
    margin-top: 0px;
  }
`;

export const FacebookAndYelpImg = styled(Img)`
  height: 46px;
`;
