import React from 'react';

import * as S from './styles';

export default function PageNotFound() {
  return (
    <S.PageNotFoundBox>
      <S.PageNotFoundTop>404</S.PageNotFoundTop>
      <S.PageNotFoundBottom>PAGE NOT FOUND</S.PageNotFoundBottom>
    </S.PageNotFoundBox>
  );
}
