import {
  Text,
  Flex,
  Link,
  Img,
  IconButton,
  Stack
} from '@chakra-ui/react';
import styled from 'styled-components';

import phone from '../../assets/Phone.svg';
import mail from '../../assets/mail.png';

export const ParentContainer = styled(Flex) `
  display: none;

  @media ${(props) => props.theme.device.laptop} {
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled(Flex)`
  display: none !important;

  @media ${(props) => props.theme.device.laptop} {
    height: 90px;
    padding-left: 50px;
    padding-right: 25px;
    display: flex !important;
    justify-content: center;
    width: 1225px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const LogoFlex = styled(Flex)`
  align-items: center;
  width: 50%;
`;

export const DropdownStack = styled(Stack)`
  background-color: ${(props) => props.theme.colors.blackPearl};
`;

export const LinkText = styled(Text)`
  color: white;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  padding-right: 10px;
`;

export const Logo = styled(Img)`
  width: 180px;
`;

export const MobileLogo = styled(Img)`
  width: 150px;
`;

export const IconsFlex = styled(Flex)`
  width: 920px;
  gap: 20px;
  justify-content: end;
  align-items: center;
  flex-direction: row;
`;

export const TextFlex = styled(Flex)`
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSizes.mobileSubtitle};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  padding-right: 54px;
`;

export const IconText = styled(Text)`
  color: ${(props) => props.theme.colors.orange};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSizes.subtitle4};
`;

export const Phone = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${(props) => props.theme.colors.orange};
  background-image: url(${phone});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
`;

export const Mail = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${(props) => props.theme.colors.orange};
  background-image: url(${mail});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  padding: 10px;
`;

export const NavFlex = styled(Flex)`
  align-items: center;
  height: 84px;
  background-color: ${(props) => props.theme.colors.blackPearl};
`;

export const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSizes.subtitle3};
  font-weight: ${(props) => props.theme.fontWeight.regular};
  color: white !important;
  height: 60px;
  margin-top: 30px;
`;

export const LinkFlex = styled(Flex)`
  flex-direction: row;
  gap: 50px;
  width: 1225px;
`;

export const NavFlex1 = styled(Flex)`
  padding-left: 20px;
`;

export const NavFlex2 = styled(Flex)`
  justify-content: end !important;
  padding-right: 20px;
`;

export const DesktopNavFLex = styled(Flex)`
  padding-left: 80px;
`;
