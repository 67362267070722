import { Button, Text } from '@chakra-ui/react';

import styled from 'styled-components';

export const StyledButton = styled(Button)`
  border-radius: 50px !important;
  font-size: ${(props) => props.theme.fontSizes.buttonFont};

  &.btn-orange {
    background-color: ${(props) => props.theme.colors.orange};

    &:hover {
      background-color: ${(props) => props.theme.colors.orange};
    }
  }

  &.btn-blackPearl {
    background-color: ${(props) => props.theme.colors.blackPearl};
    color: white;

    &:hover {
      background-color: ${(props) => props.theme.colors.blackPearl};
      color: white;
    }
  }
`;

export const CustomText = styled(Text)`
  font-weight: 100 !important;
  color: cyan;
`;
