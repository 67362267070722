import { Img, Link } from '@chakra-ui/react';
import React from 'react';

import CompanyLogo from '../../assets/CompanyLogo.svg';

import FacebookIcon from '../../assets/FacebookIcon.svg';
import GoogleIcon from '../../assets/GoogleIcon.svg';
import YelpIcon from '../../assets/YelpIcon.svg';

import * as S from './styles';

export default function Footer() {
  const footer =
    window.location.pathname == '/contact-us' ? (
      <S.CustomFlexMobileContactUs>
        <S.CompanyLogoMobile />
        <S.CopyrightTextMobileContactUs>
          Copyright © 2022 LC Masonary. All Rights Reserved.
        </S.CopyrightTextMobileContactUs>
        <S.IconBox>
          <Link href="https://www.facebook.com/LC-Masony-108163285267644">
            <S.FacebookAndYelpImg src={FacebookIcon} />
          </Link>
          <Link href="https://lc-masonary.business.site/?">
            <Img src={GoogleIcon} />
          </Link>
          <Link href="https://www.yelp.com/biz/lc-masonary-perris-2">
            <S.FacebookAndYelpImg src={YelpIcon} />
          </Link>
        </S.IconBox>
      </S.CustomFlexMobileContactUs>
    ) : (
      <>
        <S.CustomFlex>
          <S.CompanyLogo src={CompanyLogo} />
          <S.CopyrightText>
            Copyright © 2022 LC Masonary. All Rights Reserved.
          </S.CopyrightText>
          <S.IconBox>
            <Link href="https://www.facebook.com/LC-Masony-108163285267644">
              <S.FacebookAndYelpImg src={FacebookIcon} />
            </Link>
            <Link href="https://lc-masonary.business.site/?">
              <Img src={GoogleIcon} />
            </Link>
            <Link href="https://www.yelp.com/biz/lc-masonary-perris-2">
              <S.FacebookAndYelpImg src={YelpIcon} />
            </Link>
          </S.IconBox>
        </S.CustomFlex>
      </>
    );

  return <>{footer}</>;
}
