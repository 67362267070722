import {
  Box,
  Image,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import React from 'react';

import * as S from '../styles';

type Props = {
  serviceImage: string;
  serviceName: string;
};

export default function HomeServices(props: Props) {
  const { serviceImage, serviceName } = props;

  return (
    <S.BoxServiceCard
      bg={useColorModeValue('blackPearl', 'gray.800')}
      boxShadow={'2xl'}
      maxW={'330px'}
      p={6}
      pos={'relative'}
      role={'group'}
      rounded={'lg'}
      w={'full'}
      zIndex={1}
    >
      <Box
        _after={{
          transition: 'all .3s ease',
          content: '""',
          w: 'full',
          h: 'full',
          pos: 'absolute',
          top: 5,
          left: 0,
          filter: 'blur(15px)',
          zIndex: -1,
        }}
        _groupHover={{
          _after: {
            filter: 'blur(20px)',
          },
        }}
        height={'230px'}
        mt={-12}
        pos={'relative'}
        rounded={'lg'}
      >
        <Image
          height={230}
          objectFit={'cover'}
          rounded={'lg'}
          src={serviceImage}
          width={282}
        />
      </Box>
      <Stack align={'center'} pt={10}>
        <Text
          align={'center'}
          color={'white'}
          fontFamily={'body'}
          fontSize={'2xl'}
          fontWeight={500}
        >
          {serviceName}
        </Text>
      </Stack>
    </S.BoxServiceCard>
  );
}
