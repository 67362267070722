import { Img, Link } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import FacebookIcon from '../../assets/FacebookIcon.svg';
import GoogleIcon from '../../assets/GoogleIcon.svg';
import YelpIcon from '../../assets/YelpIcon.svg';
import FreeQuoteForm from '../../components/FreeQuoteForm';

import * as S from './styles';

function ContactUs() {
  useEffect(() => {
    document.title = 'LC MASONARY | CONTACT US';
  });

  const DesktopQuote = (
    <S.QuoteBox>
      <S.LeftQuoteBoxFlex>
        <S.LeftFlexBox>
          <S.QuoteCaption>Request a Free Quote</S.QuoteCaption>
          <S.QuoteSubtitle>
            Fill out the form and someone will be in contact with you shortly.
          </S.QuoteSubtitle>
          <S.SocialMediaFlex>
            <S.SocialMediaBoxFlex>
              <Link href="https://www.facebook.com/LC-Masony-108163285267644">
                <Img src={FacebookIcon} />
              </Link>
            </S.SocialMediaBoxFlex>
            <S.SocialMediaBoxFlex>
              <Link href="https://lc-masonary.business.site/?">
                <Img src={GoogleIcon} />
              </Link>
            </S.SocialMediaBoxFlex>
            <S.SocialMediaBoxFlex>
              <Link href="https://www.yelp.com/biz/lc-masonary-perris-2">
                <Img src={YelpIcon} />
              </Link>
            </S.SocialMediaBoxFlex>
          </S.SocialMediaFlex>
        </S.LeftFlexBox>
      </S.LeftQuoteBoxFlex>
      <S.RightQuoteBoxFlex>
        <FreeQuoteForm />
      </S.RightQuoteBoxFlex>
    </S.QuoteBox>
  );

  const MobileQuote = (
    <>
      <S.MobileQuoteCaption>Request a Free Quote</S.MobileQuoteCaption>
      <S.MobileQuoteSubtitle>
        Fill out the form and someone will be in contact with you shortly.
      </S.MobileQuoteSubtitle>
      <FreeQuoteForm />
    </>
  );

  return (
    <>
      <S.ServiceBox>
        <S.ConstructionBox />
        <S.Caption>CONNECT WITH US</S.Caption>
      </S.ServiceBox>
      <S.DesktopStyledBackgroundBox
        display={{
          base: 'none',
          lg: 'flex',
        }}
      >
        {DesktopQuote}
      </S.DesktopStyledBackgroundBox>
      <S.MobileStyledBackgroundBox
        display={{
          base: 'flex',
          lg: 'none',
        }}
      >
        {MobileQuote}
      </S.MobileStyledBackgroundBox>
    </>
  );
}

export default ContactUs;
