import React, { useEffect, useState } from 'react';

import Backward from '../../assets/Backward.svg';
import Forward from '../../assets/Forward.svg';

import ContactUsBanner from '../../components/ContactUsBanner';
import { services } from '../../constants/services';

import { service } from '../../types/service';

import ServiceCard from './ServiceCard';

import * as S from './styles';

export default function Services() {
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    document.title = 'LC MASONARY | SERVICES';
  });

  const servicesCards = services.map((service: service, key: number) => (
    <ServiceCard
      key={key}
      serviceImage={service.serviceImage}
      serviceName={service.serviceName}
    />
  ));

  function nextPage() {
    let temporaryPageNumber = pageNumber;

    if (pageNumber < services.length - 1) {
      temporaryPageNumber++;
      setPageNumber(temporaryPageNumber);
    }
  }

  function backPage() {
    let temporaryPageNumber = pageNumber;

    if (pageNumber !== 0) {
      temporaryPageNumber--;
      setPageNumber(temporaryPageNumber);
    }
  }

  return (
    <S.CustomFlex>
      <S.ServicesBox>
        <S.ServicesBoxBackground>{''}</S.ServicesBoxBackground>
        <S.ServicesText>SERVICES</S.ServicesText>
      </S.ServicesBox>
      <S.ServiesListBox>
        <S.ServicesListText>
          LC Masonary brings your home projects to life. From retaining walls,
          to outdoor entertainment lounge areas, to landscape ideas big or small
          LC Masonary will help guide and advise you in the right direction.
          Always with high quality material and satisfaction guaranteed. Feel
          free to preview our gallery for inspiration or even for reference
          purposes.
        </S.ServicesListText>
        <S.ServicesBoxDesktop>
          <S.ServicesListDesktop>{servicesCards}</S.ServicesListDesktop>
        </S.ServicesBoxDesktop>
        <S.ServicesBoxMobile>
          <S.ServicesListMobile>
            <S.CustomImg src={Backward} onClick={backPage} />
            <ServiceCard
              serviceImage={services[pageNumber].serviceImage}
              serviceName={services[pageNumber].serviceName}
            />
            <S.CustomImg src={Forward} onClick={nextPage} />
          </S.ServicesListMobile>
        </S.ServicesBoxMobile>
      </S.ServiesListBox>
      <ContactUsBanner />
    </S.CustomFlex>
  );
}
