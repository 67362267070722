import {
Box,
Flex,
Text,
} from '@chakra-ui/react'
import styled from 'styled-components';

import background from '../../assets/StyleBackground.png';
import construction from '../../assets/construction.jpg';

export const ConstructionBox = styled(Box)`
  background-image: url(${construction});
  height: 586px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(0.7);
`;

export const DesktopStyledBackgroundBox = styled(Flex)`
  background-image: url(${background});
  height: 830px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MobileStyledBackgroundBox = styled(Flex)`
  background-image: url(${background});
  height: 830px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
`;

export const QuoteBox = styled(Flex)`
  background: ${(props) => props.theme.colors.quoteBoxBackground};
  height: 651px;
  width: 816px;
  border-radius: 20px;
`;

export const LeftQuoteBoxFlex = styled(Flex)`
  width: 408px;
  height: 420px;
  justify-content: center;
`;

export const LeftFlexBox = styled(Flex)`
  flex-direction: column;
  justify-content: space-around;
`;

export const QuoteCaption = styled(Text)`
  width: 234px;
  padding-top: 15px;
  line-height: 1.1;
  font-size: ${(props) => props.theme.fontSizes.subtitle};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const QuoteSubtitle = styled(Text)`
  width: 288px;
  font-size: ${(props) => props.theme.fontSizes.subtitle3};
  font-weight: ${(props) => props.theme.fontWeight.regular};
`;

export const SocialMediaFlex = styled(Flex)`
  flex-direction: row;
  height: 181px;
  justify-content: space-around;
`;

export const SocialMediaBoxFlex = styled(Flex)`
  align-items: center;
`;

export const RightQuoteBoxFlex = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 408px;
`;

export const ServiceBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Caption = styled(Text)`
  position: absolute;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.subtitle};
  color: white;

  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSizes.header};
  }
`;

export const MobileQuoteFlex = styled(Flex)`
  flex-direction: column;
`;

export const MobileQuoteCaption = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.subtitle2};
`;

export const MobileQuoteSubtitle = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSizes.subtitle3};
  width: 285px;
  text-align: center;
`;
