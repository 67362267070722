import {
  Box,
  Text,
  Image,
  Flex,
  Grid,
  Img
} from '@chakra-ui/react';

import styled from 'styled-components';

import HomeHero from '../../assets/HomeHero.jpg';
import StyleBackground from '../../assets/StyleBackground.png';
import Worker from '../../assets/Worker.png';

export const CustomFlex = styled(Flex)`
  display: flex;
  flex-direction: column;
`;

export const HomeBox = styled(Box)`
  height: 587px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const HomeBoxBackground = styled(Box)`
  height: 587px;
  width: 100%;
  background-image: url(${HomeHero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(0.7);
`;
export const HomeInnerBox = styled(Text)`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items:center;
`;

export const HomeText = styled(Text)`
  max-width: 1200px;
  padding-bottom: 20px;
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.subtitle};

  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSizes.header};
  }
`;

export const HomeButton = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 10px;

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
  }
`;

export const ContactButton = styled(Flex)`
  display: block !important;

  @media ${(props) => props.theme.device.tablet} {
    display: none !important;
  }
`;

export const MainHome = styled(Box)`
  background-image: url(${StyleBackground});
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding-top: 89px;
  padding-left: 64px;
  padding-right: 64px;

  @media ${(props) => props.theme.device.laptop} {
    padding-left: 174px;
    padding-right: 174px;
  }
`;

export const Services = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 24px; */

`;

export const TitleText = styled(Flex)`
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.subtitle1};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  max-width: 1200px;
  border-radius: 0px;
  text-align: center;
  letter-spacing: 0.12em;
  color: #1a1a1a;

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${(props) => props.theme.fontSizes.subtitle};
  }
`;

export const PText = styled(Flex)`
  max-width: 1200px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subtitle4};

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${(props) => props.theme.fontSizes.subtitle3};
  }
`;

export const ServicesCardDesktop = styled(Box)`
  display: none !important;

  @media ${(props) => props.theme.device.tablet} {
    display: block !important;
  }
`;

export const ServicesCard = styled(Grid)`
  display: grid;
  margin-top: 99px;
  margin-bottom: 85px;
  grid-gap: 51px;

  @media ${(props) => props.theme.device.tablet} {
    grid-template-columns: repeat(2, 350px);
  }

  @media ${(props) => props.theme.device.laptopM} {
    grid-template-columns: repeat(4, 250px);
  }
`;

export const ServicesBoxCardMobile = styled(Box)`
  display: block !important;
  margin-top: 40px;
  margin-bottom: 71px;
  min-width: 350px;

  @media ${(props) => props.theme.device.tablet} {
    display: none !important;
  }
`;

export const ServicesListCardMobile = styled(Flex)`
  align-items: center;
  gap: 20px;
  min-width: 350px;
`;

export const CustomImg = styled(Img)`
  cursor: pointer;
`;

export const About = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media ${(props) => props.theme.device.laptopM} {
    flex-direction: row;
  }
`;

export const AboutLeft = styled(Flex)`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding-top: 46px;
  padding-bottom: 53px;

  @media ${(props) => props.theme.device.laptopM} {
    height: 700px;
    width: 577px;
  }
`;

export const AboutText = styled(Flex)`
  max-width: 1200px;
  padding-bottom: 20px;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.subtitle1};
  font-weight: ${(props) => props.theme.fontWeight.bold};  
  letter-spacing: 0.12em;
  color: #1a1a1a;
  justify-content: center;

  @media ${(props) => props.theme.device.laptopM} {
    font-size: ${(props) => props.theme.fontSizes.subtitle};
    justify-content: flex-start;
  }
`;

export const AboutPText = styled(Flex)`
  text-align: center;
  max-width: 1200px;
  padding-bottom: 20px;
  font-weight: ${(props) => props.theme.fontWeight.regular};
  font-size: ${(props) => props.theme.fontSizes.subtitle4};
  color: #1a1a1a;

  @media ${(props) => props.theme.device.laptopM} {
    font-size: ${(props) => props.theme.fontSizes.subtitle3};
    text-align: left;
  }
`;

export const IconAbout = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 334px;
`;

export const PerIconAbout = styled(Flex)`
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSizes.subtitle4};
  line-height: 21px;
  color: #1a1a1a;

  @media ${(props) => props.theme.device.laptop} {
    font-size: ${(props) => props.theme.fontSizes.subtitle3};
    text-align: left;
  }
`;

export const CustomImage = styled(Image)`
  width: 64px;
  height: 64px;
`;

export const AboutRight = styled(Flex)`
  display: none !important;
  height: 700px;
  width: 577px;
  padding-top: 46px;
  padding-bottom: 53px;

  @media ${(props) => props.theme.device.tablet} {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
  }

  @media ${(props) => props.theme.device.laptopM} {
    margin-top: 0px;
  }
`;

export const RightImage = styled(Box)`
  height: 500px;
  width: 500px;
  background-image: url(${Worker});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
`;

export const BoxServiceCard = styled(Flex)`
  display: flex;
  flex-direction: column;
  height: 370px;
  min-width: 250px;
`;
