import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Stack,
  Collapse,
  Link,
  useDisclosure,
} from '@chakra-ui/react';

import React from 'react';

import BlackLogo from '../../assets/BlackLogo.png';
import WhiteLogo from '../../assets/WhiteLogo.png';
import routes from '../../constants/routes';

import * as S from './styles';

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  interface NavItem {
    label: string;
    href: string;
  }

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: 'HOME',
      href: routes.HOME,
    },
    {
      label: 'SERVICES',
      href: routes.SERVICES,
    },
    {
      label: 'CONTACT US',
      href: routes.CONTACT_US,
    },
  ];

  const DesktopLinks = NAV_ITEMS.map((navItem) => (
    <S.StyledLink href={navItem.href} key={navItem.label}>
      {navItem.label}
    </S.StyledLink>
  ));

  const MobileNavItem = ({ label, href }: NavItem) => (
    <Stack spacing={4}>
      <Flex
        _hover={{
          textDecoration: 'none',
        }}
        align={'end'}
        as={Link}
        href={href ?? '#'}
        justify={'end'}
        py={2}
      >
        <S.LinkText>{label}</S.LinkText>
      </Flex>
    </Stack>
  );

  const MobileLinks = NAV_ITEMS.map((navItem) => (
    <MobileNavItem key={navItem.label} {...navItem} />
  ));

  const DesktopNav = () => <S.LinkFlex>{DesktopLinks}</S.LinkFlex>;

  const MobileNav = () => (
    <S.DropdownStack
      display={{
        lg: 'none',
      }}
      p={4}
    >
      {MobileLinks}
    </S.DropdownStack>
  );

  const topNav = (
    <S.ParentContainer>
      <S.Container>
        <S.LogoFlex>
          <Link href="/">
            <S.Logo src={BlackLogo} />
          </Link>
        </S.LogoFlex>
        <S.IconsFlex>
          <S.Phone />
          <S.TextFlex>
            <div>CALL US</div>
            <S.IconText>(951) 662-3173</S.IconText>
          </S.TextFlex>
          <S.Mail />
          <S.TextFlex>
            <div>MESSAGE US</div>
            <S.IconText>LS@LCMASONARY.COM</S.IconText>
          </S.TextFlex>
        </S.IconsFlex>
      </S.Container>
    </S.ParentContainer>
  );

  const Icon = isOpen ? (
    <CloseIcon boxSize={25} color="white" h={5} w={3} />
  ) : (
    <HamburgerIcon boxSize={35} color="white" h={5} w={5} />
  );

  return (
    <>
      {topNav}
      <Box>
        <S.NavFlex>
          <S.NavFlex1
            flex={{
              base: 1,
              lg: 'right',
            }}
          >
            <Flex
              display={{
                base: 'left',
                lg: 'none',
              }}
            >
              <S.MobileLogo src={WhiteLogo} />
            </Flex>
            <S.DesktopNavFLex
              display={{
                base: 'none',
                lg: 'flex',
              }}
            >
              <DesktopNav />
            </S.DesktopNavFLex>
          </S.NavFlex1>

          <S.NavFlex2
            flex={{
              base: 1,
            }}
            justify={{
              base: 'center',
              lg: 'start',
            }}
          >
            <Flex
              display={{
                base: 'right',
                lg: 'none',
              }}
            >
              <S.StyledIconButton
                aria-label={'Toggle Navigation'}
                icon={Icon}
                variant={'ghost'}
                onClick={onToggle}
              />
            </Flex>
          </S.NavFlex2>
        </S.NavFlex>

        <Collapse animateOpacity in={isOpen}>
          <MobileNav />
        </Collapse>
      </Box>
    </>
  );
}
