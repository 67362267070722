import React from 'react';

import MasonaryButton from '../MasonaryButton';

import * as S from './styles';

export default function ContactUsBanner() {
  return (
    <S.CustomFlex>
      <S.CustomText>
        ARE YOU LOOKING FOR A REPUTABLE & QUALITY CONTRACTOR?
      </S.CustomText>
      <MasonaryButton
        buttonClass={'btn-orange'}
        buttonName={'Contact Us'}
        fontSize={'20px'}
        fontWeight={'regular'}
        height={'50px'}
        path={'contact-us'}
        width={'150px'}
      />
    </S.CustomFlex>
  );
}
