
import React, { useState, useEffect } from 'react';

import Backward from '../../assets/Backward.svg';
import Fix from '../../assets/Fix.png';
import Forward from '../../assets/Forward.svg';
import House from '../../assets/House.png';
import Medal from '../../assets/Medal.png';
import MedalCheck from '../../assets/MedalCheck.png';

import ContactUsBanner from '../../components/ContactUsBanner';

import MasonaryButton from '../../components/MasonaryButton';

import { services } from '../../constants/homeServices';

import { service } from '../../types/service';

import HomeServices from './HomeServices';

import * as S from './styles';

export default function Home() {
  useEffect(() => {
    document.title = 'LC MASONARY | HOME';
  });

  const [pageNumber, setPageNumber] = useState(0);

  const servicesCards = services.map((service: service, key: number) => (
    <HomeServices
      key={key}
      serviceImage={service.serviceImage}
      serviceName={service.serviceName}
    />
  ));

  function nextPage() {
    let temporaryPageNumber = pageNumber;

    if (pageNumber < services.length - 1) {
      temporaryPageNumber++;
      setPageNumber(temporaryPageNumber);
    }
  }

  function backPage() {
    let temporaryPageNumber = pageNumber;

    if (pageNumber !== 0) {
      temporaryPageNumber--;
      setPageNumber(temporaryPageNumber);
    }
  }

  return (
    <S.CustomFlex>
      <S.HomeBox>
        <S.HomeBoxBackground>{''}</S.HomeBoxBackground>
        <S.HomeInnerBox>
          <S.HomeText>BRINGING YOUR PROJECT TO LIFE</S.HomeText>
          <S.HomeButton>
            <MasonaryButton
              buttonClass={'btn-blackPearl'}
              buttonName={'Our Services'}
              fontSize={'20px'}
              fontWeight={'regular'}
              height={'50px'}
              path={'services'}
              width={'150px'}
            />
            <S.ContactButton>
              <MasonaryButton
                buttonClass={'btn-orange'}
                buttonName={'Contact Us'}
                fontSize={'20px'}
                fontWeight={'regular'}
                height={'50px'}
                path={'contact-us'}
                width={'150px'}
              />
            </S.ContactButton>
          </S.HomeButton>
        </S.HomeInnerBox>
      </S.HomeBox>
      <ContactUsBanner />
      <S.MainHome>
        <S.Services>
          <S.TitleText>Our Services</S.TitleText>
          <S.PText>
            LC Masonary brings your home projects to life. From retaining walls,
            to outdoor entertainment lounge areas, to landscape ideas big or
            small LC Masonary will help guide and advise you in the right
            direction. Always with high quality material and satisfaction
            guaranteed. Feel free to preview our gallery for inspiration or even
            for reference purposes.
          </S.PText>
          <S.ServicesCardDesktop>
            <S.ServicesCard>{servicesCards}</S.ServicesCard>
          </S.ServicesCardDesktop>
          <S.ServicesBoxCardMobile>
            <S.ServicesListCardMobile>
              <S.CustomImg src={Backward} onClick={backPage} />
              <HomeServices
                serviceImage={services[pageNumber].serviceImage}
                serviceName={services[pageNumber].serviceName}
              />
              <S.CustomImg src={Forward} onClick={nextPage} />
            </S.ServicesListCardMobile>
          </S.ServicesBoxCardMobile>
          <MasonaryButton
            buttonClass={'btn-blackPearl'}
            buttonName={'Our Services'}
            fontSize={'20px'}
            fontWeight={'regular'}
            height={'50px'}
            path={'services'}
            width={'150px'}
          />
        </S.Services>
        <S.About>
          <S.AboutLeft>
            <S.AboutText>Who We Are</S.AboutText>
            <S.AboutPText>
              LC Masonary has been in the business for over 20 years because of
              our commitment to quality work, safety, and our customers. We are
              a family-owned business. We offer our services needed for a wide
              variety of clients in the commercial and residential construction
              industry throughout Southern California.
            </S.AboutPText>
            <S.IconAbout>
              <S.PerIconAbout>
                <S.CustomImage src={Medal} /> LC Masonary is bonded and insured
              </S.PerIconAbout>
              <S.PerIconAbout>
                <S.CustomImage src={House} /> Commercial &amp; Residential
              </S.PerIconAbout>
              <S.PerIconAbout>
                <S.CustomImage src={Fix} /> Having 20 Years of Experience
              </S.PerIconAbout>
              <S.PerIconAbout>
                <S.CustomImage src={MedalCheck} /> Quality Work
              </S.PerIconAbout>
            </S.IconAbout>
          </S.AboutLeft>
          <S.AboutRight>
            <S.RightImage />
          </S.AboutRight>
        </S.About>
      </S.MainHome>
      <ContactUsBanner />
    </S.CustomFlex>
  );
}
