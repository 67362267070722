import {
Button,
Flex,
Input,
Textarea
} from '@chakra-ui/react'
import styled from 'styled-components';

export const FormFlexBox = styled(Flex)`
  width: 300px;
  height: 470px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const FormFlex = styled(Flex)`
  background-color: ${(props) => props.theme.colors.blackPearl};
  width: 363px;
  height: 562px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const FormInput = styled(Input)`
  background: white !important;
`;

export const FormInputH = styled(Input)`
  top: 0 !important;
  left: 0 !important;
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  z-index: -1;
`;

export const FormTextArea = styled(Textarea)`
  background: white !important;
  height: 193px !important;
  resize: none;
`;

export const SubmitButton = styled(Button)`
  background: ${(props) => props.theme.colors.orange} !important;
  border-radius: 50px !important;
  width: 156px;
  height: 47px;
`;
