import {
  Box,
  Flex,
  Grid,
  Text
} from '@chakra-ui/layout';
import { Img } from '@chakra-ui/react';
import styled from 'styled-components';

import ServicesBackground from '../../assets/ServicesBackground.jpg';
import StyleBackground from '../../assets/StyleBackground.png';

export const CustomFlex = styled(Flex)`
  display: flex;
  flex-direction: column;
`;

export const ServicesBox = styled(Box)`
  height: 587px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.header};
`;

export const ServicesBoxBackground = styled(Box)`
  height: 587px;
  width: 100%;
  background-image: url(${ServicesBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(0.7);
`;

export const ServicesText = styled(Text)`
  position: absolute;  
  max-width: 1200px;
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSizes.subtitle};

  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSizes.header};
  }
`;

export const ServiesListBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-image: url(${StyleBackground});
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  padding-top: 89px;
  padding-left: 64px;
  padding-right: 64px;

  @media ${(props) => props.theme.device.laptop} {
    padding-left: 174px;
    padding-right: 174px;
  }
`;

export const ServicesListText = styled(Text)`
  max-width: 1200px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subtitle3};
`;

export const ServicesBoxDesktop = styled(Box)`
  display: none !important;

  @media ${(props) => props.theme.device.tablet} {
    display: block !important;
  }
`;

export const ServicesListDesktop = styled(Grid)`
  display: grid;
  margin-top: 99px;
  margin-bottom: 85px;
  grid-gap: 51px;

  @media ${(props) => props.theme.device.tablet} {
    grid-template-columns: repeat(2, 350px);
  }

  @media ${(props) => props.theme.device.laptopM} {
    grid-template-columns: repeat(3, 350px);
  }
`;

export const ServicesBoxMobile = styled(Box)`
  display: block !important;
  margin-top: 40px;
  margin-bottom: 71px;

  @media ${(props) => props.theme.device.tablet} {
    display: none !important;
  }
`;

export const ServicesListMobile = styled(Flex)`
  align-items: center;
  gap: 20px;
`;

export const CustomImg = styled(Img)`
  cursor: pointer;
`;
