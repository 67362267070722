import React from 'react';

import * as S from './styles';

type Props = {
  serviceImage: string;
  serviceName: string;
};

export default function ServiceCard(props: Props) {
  const { serviceImage, serviceName } = props;

  return (
    <S.CustomFlex>
      <S.ServiceImage src={serviceImage} />
      <S.ServiceName>{serviceName}</S.ServiceName>
    </S.CustomFlex>
  );
}
