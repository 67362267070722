/* eslint-disable no-undef */
import { useToast } from '@chakra-ui/react';
import emailjs from '@emailjs/browser';
import React, { useState } from 'react';

import * as S from './styles';

function FreeQuoteForm() {
  const emailService: string | undefined= (process.env.REACT_APP_EMAIL_SERVICE as string)
  const emailTemplate: string | undefined= (process.env.REACT_APP_EMAIL_TEMPLATE as string)
  const emailKey: string | undefined= (process.env.REACT_APP_EMAIL_KEY as string)

  const toast = useToast();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [website, setWebsite] = useState('');

  const sendEmail = (e: any) => {

    const params = {
      user_name: name,
      user_email: email,
      message: message,
    };

    if(website == '') {
      emailjs.send(
        emailService,
        emailTemplate,
        params,
        emailKey
      ).then(()=> {
        toast({
          title: 'Email Sent.',
          description: "We've sent your e-mail for you.",
          position: 'top-right',
          status: 'info',
          duration: 9000,
          isClosable: true,
        });
      }, () => {
        toast({
          title: 'Email Not Sent.',
          description: 'An error has occurred, please try again.',
          position: 'top-right',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })

    }
  };

  return (
    <form>
      <S.FormFlex>
          <S.FormInputH
            placeholder="Website*"
            size="md"
            onChange={(e: any) => setWebsite(e.target.value)}
          />
        <S.FormFlexBox>
          <S.FormInput
            isRequired="true"
            placeholder="Name*"
            size="md"
            onChange={(e: any) => setName(e.target.value)}
          />
          <S.FormInput
            isRequired="true"
            placeholder="Email*"
            size="md"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <S.FormTextArea
            isRequired="true"
            placeholder="Message*"
            size="lg"
            onChange={(e: any) => setMessage(e.target.value)}
          />

          <S.SubmitButton size="lg" type="reset" onClick={sendEmail}>
            Send Email
          </S.SubmitButton>
        </S.FormFlexBox>
      </S.FormFlex>
    </form>
  );
}

export default FreeQuoteForm;

